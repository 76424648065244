import React from 'react';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import Button from '../button/button';
import EventDate from '../event-date/event-date';
import EventMeta from '../event-meta/event-meta';
import Link from '../link/link';

import styles from './content-teaser.module.scss';

import VenueLabel from '../venue-label/venue-label';

const ContentTeaser = ({ data }) => {
  // Grab the image.
  let image = null;
  if (
    data?.relationships?.field_image?.max_1600_16_9
  ) {
    image = data.relationships.field_image.max_1600_16_9.childImageSharp;
  }

  const eventMeta = {
    venue: data?.relationships?.venue?.name,
    series: data?.relationships?.series?.name
  };

  // For this component we want to provide a default format for dates if one isn't supplied.
  if (data?.date) {
    data.date.format = data.date.format ? data.date.format : data.date.full;
  }

  
  return (
    <article aria-label={data?.title} className={styles.teaser}>
      {image && (
        <Link to={data?.path?.alias}>
          <Img
            alt={data?.field_image.alt}
            className={styles.img}
            sizes={image.contentTeaser}
          />
        </Link>
      )}
      <h2 className={styles.title}>{data?.title}</h2>
      {data.relationships.node_type.name === 'Event' && data?.date &&
        // Only display date if item is an Event
        <EventDate className={styles.date} date={data.date} />
      }
     {/* only display if the field_venue is not null */}
      {data.relationships.field_venue && (
        <VenueLabel venue={data.relationships.field_venue.title} path={data.relationships.field_venue.path?.alias}/>
      )}

      {data?.body && <p className={styles.body}>{data.body.summary}</p>}
      {eventMeta && (
        <EventMeta
          className={styles.meta}
          venue={eventMeta.venue && eventMeta.venue.name}
          series={eventMeta.series && eventMeta.series.name}
        />
      )}
      { data.field_summary_only !== true &&
      // ^^ The News content type has a boolean field to indicate "All of the information is included in the summary"
      // called field_summary_only. If that is checked (true), no button is printed.
      <Button
        // Pass more detailed text for screen readers.
        aria-label={`View ${data?.title} ${data?.relationships?.node_type?.name} Details`}
        className={styles.button}
        color="blueLight"
        to={data?.path?.alias}
        textStyle="upper"
      >
        <span aria-hidden="true">{
          // If item is an Event, button text is View Event
          data?.relationships?.node_type?.name === 'Event' ? 'View Event' :
          // If item is a Place, button text is Discover
          data?.relationships?.node_type?.name === 'Place' ? 'Discover' :
          // All other content types, button text is Learn More
          'Learn More'}
        </span>
      </Button>
      }
    </article>
  );
};

ContentTeaser.propTypes = {
  data: PropTypes.object.isRequired
};

export default ContentTeaser;
